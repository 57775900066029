@font-face {
  font-family: "Satoshi";
  src:
    url("/public/fonts/Satoshi-Light.woff2") format("woff2"),
    url("/public/fonts/Satoshi-Light.woff") format("woff"),
    url("/public/fonts/Satoshi-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/public/fonts/Satoshi-Regular.woff2") format("woff2"),
    url("/public/fonts/Satoshi-Regular.woff") format("woff"),
    url("/public/fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/public/fonts/Satoshi-Medium.woff2") format("woff2"),
    url("/public/fonts/Satoshi-Medium.woff") format("woff"),
    url("/public/fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/public/fonts/Satoshi-Bold.woff2") format("woff2"),
    url("/public/fonts/Satoshi-Bold.woff") format("woff"),
    url("/public/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/public/fonts/Satoshi-Black.woff2") format("woff2"),
    url("/public/fonts/Satoshi-Black.woff") format("woff"),
    url("/public/fonts/Satoshi-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
